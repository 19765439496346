.Footer {
  height: fit-content;
  width: 100%;
  background: rgb(0, 45, 112);
  clear: both;
  position: relative;
  top: 164px;
  margin-top: 100px;
}
.FooterWrap {
  width: 100%;
  padding: 15px 0px;
  height: fit-content;
  margin: 0 auto;
}
.Footer ul {
    display: block;
  color: #fff;
  font-family: "Source Sans 3";
}
.FooterWrap ul:first-child,
:nth-child(2) {
  top: -90px;
}
.FooterWrap ul li {
  list-style: none;
  font-size: 14px;
  padding: 10px 0;
}
.Footer ul p {
  font-size: 10px;
  line-height: 20px;
}
.copyright {
  text-align: center;
  color: #fff;
  font-family: "Source Sans 3";
  padding: 20px 0px;
}

@media (min-width: 760px) {
    .FooterWrap {
        width: 85%;
    }
  .FooterWrap ul {
    display: inline-block;
    width: 29.5%;
  }
}
