.Header {
    height: 150px;
    width: 100%;
}
.logo {
    font-family: 'Abril Fatface', cursive;
    font-size: 30px;
    line-height: 150px;
    margin-left: 10%;
    float: left;
    transition: 0.6s;
}
.Header nav {
    float: right;
    line-height: 125px;
    margin-right: 10%;
    display: none;
}
.Header nav ul {
    font-family: 'Source Sans 3';
    color: rgb(0, 102, 255);
}
.Header nav ul li {
    display: inline-block;
    padding-left: 30px;
    cursor: pointer;
}
@media(min-width: 760px) {
    .Header nav {
        display: inline-block;
    }
}