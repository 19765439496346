.ContactUs {
    width: 80%;
    height: fit-content;
    margin: 0 auto;
}
.ContactUs p {
    font-size: 18px;
}
.ContactUs ul {
    display: block;
}
.ContactUs ul li {
    list-style: none;
    display: block;
    margin: 30px 0px;
    width: fit-content;
}
.ContactUs ul li span {
    border-bottom: 3px solid rgb(0, 102, 255);
    padding-bottom: 5px;
}
.ContactUs ul li i {
    color: rgb(0, 102, 255);
    margin-right: 5px;
}