.Products {
    width: 80%;
    height: fit-content;
    margin: 0 auto;
}

.ProductWrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 5%;
    height: fit-content;
    margin-top: 50px;
}