#About {
  height: fit-content;
  width: 80%;
  margin: 0 auto;
  font-family: "Source Sans 3";
  margin-bottom: 50px;
}
#About .ceo {
  display: block;
  font-size: 18px;
}
#About .ceo p span {
  font-weight: 500;
}
#About .ceo img {
  width: 250px;
  height: 250px;
  margin: 0;
  border-radius: 50%;
}
#About img {
  height: 40px;
  width: 90px;
  margin: 40px auto;
  display: block;
  object-fit: cover;
}
#About h1 {
  font-weight: 500;
  border-bottom: 3px solid rgb(0, 102, 255);
  width: fit-content;
}
#About p {
  line-height: 28px;
  font-size: 18px;
}
#About p span {
  color: rgb(0, 102, 255);
}

@media (max-width: 768px) {
  #About .ceo {
    width: 100%;
  }
  #About .ceo img {
    margin: 0 auto;
  }
  #About .ceo p {
    text-align: center;
  }
}
