.Menu {
    height: 100%;
    width: 100%;
    background: rgb(0, 102, 255);
    position: absolute;
    z-index: 1;
    transition: 0.5s;
    overflow: hidden;
    transition-timing-function: ease-in-out;
}

.Menu nav {
    float: left;
    margin: 0;
    display: block;
    margin: 0 auto;
    width: 90%;
    transition-timing-function: ease-in;
    transition-duration: 0.3s;
    transition-delay: 0.3s;
    line-height: 90px;
    position: relative;
    top: 150px;
}
.Menu nav ul{
    font-family: 'Source Sans 3';
    color: #fff;
    font-size: 2rem;
}
.Menu nav ul li {
    display: block;
    padding: 0;
    margin: 0;
    text-align: center;
}

@media (min-width: 760px) {
    .Menu nav{
        display: none;
    }
}