.Product {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  height: fit-content;
  position: relative;
  top: 0;
  margin-bottom: 50px;
  border-radius: 2px;
  overflow: hidden;
  background: #ffffff;
}
.Product img {
  width: 100%;
  height: 300px;
  display: block;
  object-fit: cover;
}
.Product .ProductDescription {
  width: 100%;
  padding: 0px 10px;
  padding-top: 10px;
  padding-bottom: 60px;
  position: relative;
  top: 0px;
  bottom: 0px;
}
.Product .ProductDescription h1 {
  text-transform: capitalize;
}
.Product .ProductDescription p {
  line-height: 26px;
  color: #676767;
}
@media (min-width: 760px) {
  .Product {
    flex: 1 0 45%;
  }
  .Product img {
    display: inline-block;
    border-top-right-radius: 0;
    height: 300px;
  }
  .Product .ProductDescription {
    display: inline-block;
    width: 90%;
    padding: 0 0;
    margin: 0 5%;
    padding-bottom: 20px;
  }
}
