.Home {
    height: 100%;
    width: 100%;
}
.Home section {
    background: linear-gradient(to right, rgba(0, 102, 255, 0.7), rgba(33, 172, 177, 50%)), url('../../assets/images/background1.webp');
    background-size: cover;
    width: 100%;
    height: 450px;
}
.sectionWrap {
    margin: 0 auto;
    width: 80%;
}
.sectionTitle {
    font-family: 'Source Sans 3';
    color: #fff;
    font-size: 2rem;
    line-height: 55px;
    width: 95%;
    position: relative;
    top: 100px;
}
.BrandDescription {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 100px;
}
.BrandDescription img {
    height: 40px;
    width: 90px;
    margin: 40px auto;
    display: block;
}
.BrandDescription h2 {
    font-weight: 600;
    font-family: 'Source Sans 3';
    border-bottom: 3px solid rgba(0, 102, 255, 1);
    width: fit-content;
    letter-spacing: 3px;
}
.BrandDescription p {
    font-size: 18px;
    line-height: 26px;
    font-family: 'Source Sans 3';
    margin-bottom: 50px;
}
.BrandDescription p span {
    color: rgba(0, 102, 255, 1);
}
@media(min-width: 760px) {
    .sectionTitle {
        font-size: 2.5rem;
        width: 50%;
        top: 120px;
        line-height: 65px;
    }
    .BrandDescription {
        width: 70%;
    }
}